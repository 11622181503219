import { APIFilter } from '@/utils/api'

export default {
  async selectSistemaTviacomunicacion (Vue, idsistemaTviacomunicacion) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idsistema_tviacomunicacion', idsistemaTviacomunicacion)
    const resp = await Vue.$api.call('sistemaTviacomunicacion.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
}
